<template>
  <div class="class-container" v-loading="loading">
    <template v-if="existClass==1">
      <div class="class-top">
        <h4>班级管理</h4>
        <el-button class="btn-create" type="primary" size="large"
                   @click="dialogVisible=true"
                   icon="el-icon-circle-plus-outline">创建班级
        </el-button>
      </div>
      <div class="class-list">
        <template v-for="(item, index) of classList">
          <div class="class-list__item" :key="item.id">
            <div class="class-list__item-left">
              <div class="class-list__item-top">
                <div class="class-list__item-top-grade">{{ item.gradeId | formatGradeName }}</div>
                <h6 class="class-list__item-top-name">{{ item.name }}</h6>
                <span class="class-list__item-top-id">(ID：{{ item.sequence }})</span>
                <img
                    @click="copyClassId(item.sequence)"
                    class="class-list__item-top-copy"
                    src="../../assets/icon-copy.png" alt="copy"/>
              </div>
              <div class="class-list__item-bottom">
                <div class="class-list__item-bottom-device">个人端 <strong>{{ item.count }}</strong> 人</div>
                <div class="class-list__item-bottom-device">考试端 <strong>{{ item.examineeCount || 0 }}</strong> 人</div>
              </div>
            </div>
            <div class="class-list__item-jump"
                 @click="$router.push({name: 'ClassDetails', params: {id: item.id}, query:{name:item.name}})">
              <span>进入班级</span>
              <img class="class-list__item-jump-arrow" src="../../assets/icon-arrow-right-blue.png" alt="copy"/>
            </div>
          </div>
          <div :key="item.id + index" class="class-list__item-line"></div>
        </template>
      </div>
    </template>

    <div class="guide-classes-create" v-else>
      <div class="icon-classes-none"></div>
      <p>您还没有班级哦~请先前往创建新班级</p>
      <p style="margin-bottom: 50px;">或致电：400-996-0201，我们的客服人员会为您创建</p>
      <el-button class="btn-create" type="primary" size="large"
                 @click="dialogVisible=true"
                 icon="el-icon-circle-plus-outline">创建班级
      </el-button>
    </div>

    <dialog-class
        v-if="dialogVisible"
        :visible="dialogVisible"
        @updated="loadClassListData"
        @close="dialogVisible=false"/>
  </div>
</template>

<script>
import {getClassesList} from "@/api/classes";
import DialogClass from "@/views/classes/components/dialogClass";

export default {
  name: "ClassManage",
  components: {DialogClass},
  data() {
    return {
      loading: false,
      existClass: 0,
      dialogVisible: false,
      classList: [],
    }
  },
  created() {
    this.loadClassListData()
  },
  methods: {
    async loadClassListData() {
      this.loading = true
      try {
        const res = await getClassesList()
        if (res.state === '11') {
          const {data} = res
          this.classList = data
          let haveClass = false
          if (data.length !== 0) {
            this.existClass = 1
            haveClass = true
          }
          this.$store.commit('haveClassChange', haveClass)
        }
      } catch (e) {
      }
      this.loading = false
    },
    /**
     * 复制班级 ID
     * @param id
     */
    copyClassId(id) {
      if (document.execCommand('copy')) {
        let input = document.createElement('input')
        input.value = id
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        input.style.display = 'none'
        this.$message.success('已复制班级ID')
      } else {
        this.$message.warning('该浏览器不支持，请手动复制。')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.class-container {
  width: 1100px;
  min-height: calc(100vh - 80px);

  .class-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 84px;
    color: #333333;
    font-size: 20px;
    background-color: #FFFFFF;
    padding: 0 28px;
  }

  .class-list {
    min-height: calc(100vh - 174px);
    background-color: #FFFFFF;
    padding: 14px 28px;
    margin-top: 10px;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 28px;

      &:hover {
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
        border-radius: 8px;
      }

      &-top {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        &-grade {
          width: 59px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #309af2;
          font-size: 13px;
          background: #EAF4FD;
          border-radius: 4px;
          margin-right: 14px;
        }

        &-name {
          color: #333333;
          font-size: 17px;
          margin-right: 14px;
        }

        &-id {
          color: #333333;
          font-size: 13px;
          margin-right: 14px;
        }

        &-copy {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }

      &-bottom {
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 16px;

        &-device {
          margin-right: 28px;
        }

        strong {
          color: #333333;
          font-size: 19px;
        }
      }

      &-jump {
        display: flex;
        align-items: center;
        width: 112px;
        height: 40px;
        color: #309AF2;
        font-size: 15px;
        border-radius: 4px;
        border: 1px solid #309AF2;
        cursor: pointer;
        padding: 0 14px;

        &:hover {
          background-color: #eaf5fe;
        }

        &-arrow {
          width: 14px;
          height: 14px;
          margin-left: 8px;
        }
      }

      &-line {
        height: 1px;
        background-color: #F1F1F1;
        margin: 0 28px;
      }
    }
  }
}

.guide-classes-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
  height: calc(100vh - 80px);
  line-height: 34px;
  background-color: #ffffff;

  .icon-classes-none {
    width: 173px;
    height: 155px;
    background: url("../../assets/icon-classes-none.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
  }
}

.btn-create {
  width: 138px;
  border: none;
  font-size: 15px;
  background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);

  &:hover {
    background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
  }
}

</style>
